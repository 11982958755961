import axios from 'axios';
const isOnline = require('is-online');
const  iconv = require('iconv-lite');


// import dotenv from 'dotenv';
// const config = dotenv.config()



// // let config = process.env
// console.log(config)

async function loginApi(username, password) {
    try {
        console.log(`${process.env.REACT_APP_backend_api_url}/login`, { username, password })
        let result = await axios.post(`${process.env.REACT_APP_backend_api_url}/login`, { username, password }, { withCredentials: true })
        console.log('START Login user');
        console.log('loginUser::', result.data)
        console.log('END Login user');

        return result.data
    } catch (e) {
        console.log(e)
        return e
    }
    // .then((res) => { console.table(res.data) })
    // .catch((error) => { console.error(error) })
}

async function logoutApi() {
    try {
        console.log(`${process.env.REACT_APP_backend_api_url}/logout`)
        let result = await axios.post(`${process.env.REACT_APP_backend_api_url}/logout`, {}, { withCredentials: true })
        console.log('data::', result.data)
        return result.data
    } catch (e) {
        console.log(e)
        return e
    }
}

async function checkLoginApi() {
    try {
        console.log(`${process.env.REACT_APP_backend_api_url}/login-check`)
        let result = await axios.get(`${process.env.REACT_APP_backend_api_url}/login-check`, { withCredentials: true })
        console.log('checkLogin::', result.data)
        return result.data
    } catch (e) {
        console.log(e)
    }
}

async function enauth() {
    try {
        console.log(`${process.env.REACT_APP_backend_api_url}/test-enauth`)
        let result = await axios.get(`${process.env.REACT_APP_backend_api_url}/test-enauth`, { withCredentials: true })
        console.log('data::', result.data)
        return result.data
    } catch (e) {
        console.log(e)
    }

}

async function menuApi(shop_id) {
    try {
        console.log(`${process.env.REACT_APP_backend_api_url}/menu/${shop_id}`)
        let result = await axios.get(`${process.env.REACT_APP_backend_api_url}/menu/${shop_id}`, { withCredentials: true })
        console.log('data::', result.data)
        return result.data
    } catch (e) {
        return false
        console.log(e)
    }
}

async function bindCOMenu(account_id) {
    try {
        console.log(`${process.env.REACT_APP_backend_api_url}/co-menu/${account_id}`)
        let result = await axios.post(`${process.env.REACT_APP_backend_api_url}/co-menu/${account_id}`, { withCredentials: true })
        console.log('bindCOMenu::', result.data)
        return result.data
    } catch (e) {
        console.log('e::', e.message)
        return e
    }
}

async function sequenceApiGet(account_id) {
    try {
        console.log(`${process.env.REACT_APP_backend_api_url}/counter-sequence-all/${account_id}`)
        let result = await axios.get(`${process.env.REACT_APP_backend_api_url}/counter-sequence-all/${account_id}`, { withCredentials: true })
        return result.data

    } catch (e) {
        console.log(e)
    }
}

async function getTableNumbers(account_id) {
    try {
        console.log(`${process.env.REACT_APP_backend_api_url}/business-table-numbers/${account_id}`)
        let result = await axios.get(`${process.env.REACT_APP_backend_api_url}/business-table-numbers/${account_id}`, { withCredentials: true })
        return result.data

    } catch (e) {
        console.log(e)
    }
}

async function getShopId(account_id) {
    try {
        console.log(`${process.env.REACT_APP_backend_api_url}/business-shop-id/${account_id}`)
        let result = await axios.get(`${process.env.REACT_APP_backend_api_url}/business-shop-id/${account_id}`, { withCredentials: true })
        console.log('getShopId:::', result.data)
        return result.data

    } catch (e) {
        console.log(e)
        return false
    }
}

async function getShopName(account_id) {
    try {
        console.log(`${process.env.REACT_APP_backend_api_url}/business/${account_id}`)
        let result = await axios.get(`${process.env.REACT_APP_backend_api_url}/business/${account_id}`, { withCredentials: true })
        console.log('getShopName:::', result.data)
        return result.data

    } catch (e) {
        console.log(e)
        return false
    }
}

async function getSiTitleLogo(account_id) {
    try {
        console.log(`${process.env.REACT_APP_backend_api_url}/si-title-logo/${account_id}`)
        let result = await axios.get(`${process.env.REACT_APP_backend_api_url}/si-title-logo/${account_id}`, { withCredentials: true })
        console.log('getSiTitleLogo:::', result.data)
        if (result.data && result.data.result && result.data.result.length) {
            return result.data.result[0]
        } else {
            throw new Error('getSiTitleLogo沒有result.length')
        }
        // return result.data
    } catch (e) {
        throw e
    }
}

async function postOrder({ shop_id, tableNumber, amount, mealType, carts, discounts, clientOptions, source, selectedModifierGroups, specialInstructions, carrierId, taxId, isOnlinePayment }) {
    try {

        console.log(`${process.env.REACT_APP_backend_api_url}/order/${shop_id}`)

        let post_payload = {
            // "tableNumber":"1",
            // "tableNumber": tableNumber,
            // "amount": 90, // 訂單金額
            "amount": amount,
            // "pickupTs": 1610607660, // 消費者預期取餐時間
            "pickupTs": Math.floor(new Date().getTime() / 1000),
            "driverPickupTs": null, // 如果有採用第三方外送服務(e.g., lalamove)，此為外送員取餐時間
            "mealType": mealType, // eat_in 內用, to_go 外帶, delivery 外送
            "paymentType": "cash", // cash, multiple, qrpay_credit, qrpay_line, qrpay_google, qrpay_apple, qrpay_easywallet
            "carts": carts,
            user: {
            },
            discounts,
            clientOptions,
            source,
            user: {
                taxId: ''
            },
            "orderInstructions": {
                "selectedModifierGroups": selectedModifierGroups ? selectedModifierGroups : [],
                "specialInstructions": specialInstructions ? specialInstructions : ''
            },
        }
        if (taxId) {
            post_payload.user.taxId = taxId
        }
        if (carrierId) {
            post_payload.user.carrierId = carrierId //
        }
        if (mealType !== 'to_go' && mealType !== 'toGo' && mealType !== 'delivery') {
            post_payload['tableNumber'] = tableNumber
        }

        if (isOnlinePayment) {
            post_payload['paymentType'] = 'multiple'
            post_payload['payment'] = 'success'
        }
        console.log('isOnlinePayment:', isOnlinePayment)
        console.log(JSON.stringify(post_payload))
        if (carrierId) {
            post_payload['receipt'] = {}
            post_payload['receipt']['carrierType'] = 'mobile'
        }

        // require('dns').resolve('https://www.google.com', async function(err) {
        //     if (err) {
        //         console.log("No connection");
        //         throw new Error('連不到google')
        //     } else {
        //         console.log("Connected");

        let result = await axios.post(
            `${process.env.REACT_APP_backend_api_url}/order/${shop_id}`,
            post_payload,
            { withCredentials: true })

        console.log('order api result:', result)
        console.log('postOrder api:::', result.data)
        return result.data
        //     }
        // });
    } catch (e) {

        console.log('e:::', e)
        let isOnlineNow = await isOnline()
        if (!isOnlineNow) {
            throw new Error('沒有網路')
        } else if (e.message.indexOf('Network Error') > -1) {
            throw new Error('連不到api')
            // setLoginNoWifi(true)
        } else {
            console.log('post order error:', e.response.data)
            if (e.response && e.response.data && e.response.data.error) {
                throw new Error(e.response.data.error)
            } else {
                throw e
            }
        }
    }
}

async function getOrder(order_id) {
    try {
        console.log(`${process.env.REACT_APP_backend_api_url}/order/${order_id}`)
        let result = await axios.get(`${process.env.REACT_APP_backend_api_url}/order/${order_id}`, { withCredentials: true })
        console.log('getOrder:', result.data)
        return result.data

    } catch (e) {
        console.log(e)
        return false
    }
}

async function getOrders(account_id, searchString, platformChoose) {
    try {
        console.log('getOrders api', account_id, searchString, platformChoose)
        if (searchString) {
            if (platformChoose) {
                console.log(1)
                console.log(`${process.env.REACT_APP_backend_api_url}/orders/${account_id}?search=${searchString}&platforms=${JSON.stringify(platformChoose)}`)
                let result = await axios.get(`${process.env.REACT_APP_backend_api_url}/orders/${account_id}?search=${searchString}&platforms=${JSON.stringify(platformChoose)}`, { withCredentials: true })
                console.log('getOrders:::', result.data)
                return result.data
            } else {
                console.log(2)
                console.log(`${process.env.REACT_APP_backend_api_url}/orders/${account_id}?search=${searchString}`)
                let result = await axios.get(`${process.env.REACT_APP_backend_api_url}/orders/${account_id}?search=${searchString}`, { withCredentials: true })
                console.log('getOrders:::', result.data)
                return result.data
            }
        } else {
            if (platformChoose) {
                console.log(`${process.env.REACT_APP_backend_api_url}/orders/${account_id}?platforms=${JSON.stringify(platformChoose)}`)
                let result = await axios.get(`${process.env.REACT_APP_backend_api_url}/orders/${account_id}?platforms=${JSON.stringify(platformChoose)}`, { withCredentials: true })
                console.log('getOrders:::', result.data)
                return result.data
            } else {
                console.log(`${process.env.REACT_APP_backend_api_url}/orders/${account_id}`)
                let result = await axios.get(`${process.env.REACT_APP_backend_api_url}/orders/${account_id}`, { withCredentials: true })
                console.log('getOrders:::', result.data)
                return result.data
            }

        }

    } catch (e) {
        console.log(e)
        return false
    }
}

async function reprint(order_id, user_info) {
    try {
        console.log(`${process.env.REACT_APP_backend_api_url}/reprint/order/${order_id}`)
        let result = await axios.post(`${process.env.REACT_APP_backend_api_url}/reprint/order/${order_id}`, { user_info }, { withCredentials: true })
        console.log('reprint:::', result.data)
        return result.data

    } catch (e) {
        console.log(e)
        throw (e)
    }
}

async function post_co_action_logs(account_id, order_id, actions, amount, discount) {
    try {
        console.log(`${process.env.REACT_APP_backend_api_url}/coaction-logs`, account_id, order_id, actions, amount, discount)
        let result = await axios.post(`${process.env.REACT_APP_backend_api_url}/co-action-logs`, {
            account_id, order_id, actions, amount, discount
        }, { withCredentials: true })
        console.log('reprint:::', result.data)
        return result.data

    } catch (e) {
        console.log(e)
        return false
    }
}


async function getInvoiceStatusApi(order_id) {
    try {
        console.log(`${process.env.REACT_APP_backend_api_url}/invoice/status?order_id=${order_id}`)
        let result = await axios.get(`${process.env.REACT_APP_backend_api_url}/invoice/status?order_id=${order_id}`, { withCredentials: true })
        console.log('getInvoiceStatusApi:::', result.data)
        return result.data

    } catch (e) {
        console.log(e)
        throw (e)
    }
}


async function postIssueApi(order_id, postBody) {
    try {
        console.log(`${process.env.REACT_APP_backend_api_url}/invoice/issue`)
        let result = await axios.post(`${process.env.REACT_APP_backend_api_url}/invoice/issue?order_id=${order_id}`, postBody, { withCredentials: true })
        console.log('postIssueApi:::', result.data)
        return result.data

    } catch (e) {
        console.log(e)
        throw (e)
    }
}


async function cancelIssueApi(order_id) {
    try {
        console.log(`${process.env.REACT_APP_backend_api_url}/invoice/cancel?order_id=${order_id}`)
        let result = await axios.post(`${process.env.REACT_APP_backend_api_url}/invoice/cancel?order_id=${order_id}`, { withCredentials: true })
        console.log('cancelIssueApi:::', result.data)
        return result.data

    } catch (e) {
        console.log(e)
        throw (e)
    }
}


async function printIssueApi(order_id) {
    try {
        console.log(`${process.env.REACT_APP_backend_api_url}/invoice/print`)
        let result = await axios.post(`${process.env.REACT_APP_backend_api_url}/invoice/print?order_id=${order_id}`, { withCredentials: true })
        console.log('printIssueApi:::', result.data)
        return result.data

    } catch (e) {
        console.log(e)
        throw (e)
    }
}

async function getInvoiceInformationApi(order_id) {
    try {
        console.log(`${process.env.REACT_APP_backend_api_url}/invoice/information?order_id=${order_id}`)
        let result = await axios.get(`${process.env.REACT_APP_backend_api_url}/invoice/information?order_id=${order_id}`, { withCredentials: true })
        console.log('getInvoiceStatusApi:::', result.data)
        return result.data

    } catch (e) {
        console.log(e)
        return false
    }
}

async function cancelOrder(order_id) {
    try {
        console.log(`${process.env.REACT_APP_backend_api_url}/order/${order_id}`)
        let result = await axios.post(`${process.env.REACT_APP_backend_api_url}/order/${order_id}/cancel`, {}, { withCredentials: true })
        console.log('cancelOrder:', result.data)
        return result.data

    } catch (e) {
        console.log(e)
        throw (e)
    }
}

async function patchOrder(order_id, data) {
    try {
        console.log(`${process.env.REACT_APP_backend_api_url}/order/${order_id}`, JSON.stringify(data))
        // let result = await axios.post(`${process.env.REACT_APP_backend_api_url}/order/${order_id}/cancel`, { withCredentials: true })
        let result = await axios.patch(`${process.env.REACT_APP_backend_api_url}/order/${order_id}`, data, { withCredentials: true })

        console.log('patchOrder:', result.data)
        return result.data

    } catch (e) {
        console.log(e)
        throw (e)
    }
}


async function isDDCInvoiceStoreApi(account_id) {
    try {
        console.log(`${process.env.REACT_APP_backend_api_url}/invoice/is_ddc_account?account_id=${account_id}`)
        let result = await axios.get(`${process.env.REACT_APP_backend_api_url}/invoice/is_ddc_account?account_id=${account_id}`, { withCredentials: true })
        console.log('isDDCInvoiceStoreApi:::', result.data)
        return result.data

    } catch (e) {
        console.log(e)
        throw (e)
    }
}

async function getPosDvrApi(account_id) {
    try {
        console.log(`${process.env.REACT_APP_backend_api_url}/business-shop-id/${account_id}/posdvr`)
        let result = await axios.get(`${process.env.REACT_APP_backend_api_url}/business-shop-id/${account_id}/posdvr`, { withCredentials: true });
        console.log('getPosDvrApi::::::::', result.data)
        return result.data
    } catch (error) {
        console.log(e)
        throw (e)
    }
}

async function loginLocalDvr(posDvrName, posDvrPassword, posDvrIP, isEnabled) {
    try {
        console.log(`${process.env.REACT_APP_backend_api_url}/posdvr`, { posDvrName, posDvrPassword })
        let result = await axios.post(`${process.env.REACT_APP_backend_api_url}/posdvr`, { posDvrName, posDvrPassword, posDvrIP, isEnabled }, { withCredentials: true })
        // let result = await axios({
        //     method: 'post',
        //     // url: `http://${posDvrIP}/login.cgi`,
        //     url: `${posDvrIP}/login.cgi`,
        //     withCredentials: true,
        //     headers: {
        //         'Content-Type': `text/plain`
        //     },
        //     data: 'na=admin&pa=admin'
            // method: 'post',
            // url: `http://${posDvrIP}/protect/PosWrite.htm`,
            // headers: { 
            //   'Cookie': `auth=${authCookie}`, 
            //   'Content-Type': `text/plain`
            // },
            // data : big5Data
        // })
        console.log("LoginLocalDvr:::", result.data)
        return result.data
    } catch (error) {
        console.log(error)
        return error
    }
}

async function getAuthCookie(posDvrName, posDvrPassword, posDvrIP) {
    try {
        // console.log(`${process.env.REACT_APP_backend_api_url}/posdvr`, { posDvrName, posDvrPassword })
        // const apiURL = `${posDvrIP}/login.cgi?na=${posDvrName}&pa=${posDvrPassword}`;
        let result = await axios.get(`${process.env.REACT_APP_backend_api_url}/posdvr?na=${posDvrName}&pa=${posDvrPassword}&ip=${posDvrIP}`, { withCredentials: true })
        console.log("GETAUTHCOOKIE::::", result.data)
        // let result = await axios({
        //     method: 'GET',
        //     url: `${apiURL}`,
        //     withCredentials: true,
        // })
        
        // let result = await axios.get(`http://${posDvrIP}/login.cgi?na=${posDvrName}&pa=${posDvrPassword}`, { withCredentials: true });
        const authCookie = result.data;
        console.log("AUTHCOOKIE")
        console.log(authCookie)
        console.log("AUTHCOOKIE")
        return result.data
    } catch (error) {
        console.log("Error getAuthCookie Error")
        console.log(error)
        return error
    }
}

async function writePosDvrTag(posDvrIP, authCookie, channel, currentTime) {
    try {
        console.log(`${process.env.REACT_APP_backend_api_url}/posdvr`, { posDvrIP, authCookie, channel, currentTime })
        let result = await axios.post(`${process.env.REACT_APP_backend_api_url}/posdvr`, { posDvrIP, authCookie, channel, currentTime })
        console.log("WRITEPOSDVRTAG:::", result.data)
        
        console.log("WRITETAG")
        console.log(result)
        console.log("WRITETAG")
        return result.data
    } catch (error) {
        console.log(error)
        return error
    }
}




export default {loginApi,checkLoginApi,enauth,menuApi,bindCOMenu,sequenceApiGet,
    getTableNumbers,getShopId,getShopName,postOrder,logoutApi,getOrders,
    getOrder,reprint,post_co_action_logs,
    getInvoiceStatusApi,postIssueApi,cancelIssueApi,printIssueApi,getInvoiceInformationApi,
    cancelOrder,patchOrder,isDDCInvoiceStoreApi,getSiTitleLogo,
    getPosDvrApi,loginLocalDvr,getAuthCookie,writePosDvrTag
}