import { Howl, Howler } from 'howler';


/*
  ex. order 22571 status: "accepted" -> "done"

  根據前端登入後的 shopId, listen不同的shops collection
  listen to firestore shops->orders->status
  start voice-call function
  get orderDetail by orderId (displayNumber, platform, shopId, mealType)
  分解 displayNumber 來獲取對應音檔
  分解 platform 來獲取對應音檔
  組合 displayNumber, platform 音檔
  播放 組合完成之音檔

*/

let isPlaying = false;
let queue = [];

async function playVoiceCall(orderId, orderStatus, orderPlatform, orderMealType, orderNumber) {
  console.log('START Voice call !!');

  // order voice call logic
  // 內用,外帶, 外送 (quickclick / ubereats / foodpanda / counter)
  let a = [];
  if (orderMealType === 'eat_in') {
    a.push('內用');
    pureNumberConvert(orderNumber);
  }
  else if (orderMealType === 'to_go') {
    a.push('外帶');
    pureNumberConvert(orderNumber);
  }
  else if (orderMealType === 'delivery') {
    if (orderPlatform === 'ubereats') {
      a.push('ubereat');
      deliveryNumberCall(orderNumber, a);
    }
    else if (orderPlatform === 'foodpanda') {
      a.push('foodpanda');
      deliveryNumberCall(orderNumber, a);
    }
  }


  function pureNumberConvert(orderNumber) {
    // 號碼
    // 1 ~ 9
    if (orderNumber < 10) {
      let numberString = orderNumber.toString(); // 1 2 3 4 5 6 7 8 9
      unitDigitWithout0(numberString[0], a);
    }
    // 10 ~ 99
    if (orderNumber >= 10 && orderNumber <= 99) {
      let numberString = orderNumber.toString().split(''); // 1,0  2,5
      console.log(numberString);
      tensDigitUnder100(numberString, a);
      unitDigitWithout0(numberString[1], a);
    }
    // 100 ~
    if (orderNumber >= 100) {
      let numberString = orderNumber.toString().split(''); // 1,0  2,5

      for (let index = 0; index < numberString.length; index++) {
        const element = numberString[index];
        a.push(element);
      }
    }
  }

  const prefix = ['來賓'];
  const postfix = ['號', '請來櫃檯取餐'];
  let combination = prefix.concat(a).concat(postfix);

  queue.push(combination);
  console.log(queue);

  function play_audio(file_names) {
    let sound = new Howl({
      src: [`${process.env.REACT_APP_backend_api_url}` + '/' + file_names[0] + '.mp3'],
      onend: function () {
        file_names.shift();
        if (file_names.length > 0) {
          play_audio(file_names);
          console.log(isPlaying);
        } else {
          // play next combination
          isPlaying = false;
          queue.shift();
          console.log(queue);
          console.log(isPlaying);
        }
      }
    });

    sound.play();

  }

  if (queue.length === 1) {
    let timeoutID = window.setInterval(() => {

      if (queue.length === 0) {
        window.clearInterval(timeoutID);
      }

      console.log(isPlaying);
      if (!isPlaying && queue.length > 0) {
        play_audio(queue[0]);
        isPlaying = true
      }
    }, 1000);
  }

  console.log('END Voice call !!');
}


function tensDigitUnder100(numberString, a) {
  switch (numberString[0]) {
    case '1':
      a.push('10');
      break;
    case '2':
      a.push('2');
      a.push('10');
      break;
    case '3':
      a.push('3');
      a.push('10');
      break;
    case '4':
      a.push('4');
      a.push('10');
      break;
    case '5':
      a.push('5');
      a.push('10');
      break;
    case '6':
      a.push('6');
      a.push('10');
      break;
    case '7':
      a.push('7');
      a.push('10');
      break;
    case '8':
      a.push('8');
      a.push('10');
      break;
    case '9':
      a.push('9');
      a.push('10');
      break;
    default:
      break;
  }
}

function unitDigitWithout0(numberString, a) {
  switch (numberString) {
    case '0':
      break;
    case '1':
      a.push('1');
      break;
    case '2':
      a.push('2');
      break;
    case '3':
      a.push('3');
      break;
    case '4':
      a.push('4');
      break;
    case '5':
      a.push('5');
      break;
    case '6':
      a.push('6');
      break;
    case '7':
      a.push('7');
      break;
    case '8':
      a.push('8');
      break;
    case '9':
      a.push('9');
      break;
    default:
      break;
  }
}

function deliveryNumberCall(orderIdString, a) {
  let orderIdArray = orderIdString.toString().split(''); // ADG36
  for (let i = 0; i < orderIdArray.length; i++) {
    const element = orderIdArray[i];
    a.push(element.toUpperCase());
  }
}

// function checkIsPlaying(sound) {
//   sound.
// }

export default {
  playVoiceCall
}