// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { 
  getFirestore, 
  doc, 
  collection, 
  query, 
  where, 
  onSnapshot,
} from "firebase/firestore";
import { getAuth } from "firebase/auth";

import voiceCall from "./voiceCall";

// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries


// Your web app's Firebase configuration
const firebaseConfig = {
  apiKey: `${process.env.REACT_APP_FIRESTORE_API_KEY}`,
  authDomain: `${process.env.REACT_APP_FIRESTORE_AUTH_DOMAIN}`,
  databaseURL: `${process.env.REACT_APP_FIRESTORE_DB_URL}`,
  projectId: `${process.env.REACT_APP_FIRESTORE_PROJECT_ID}`,
  storageBucket: `${process.env.REACT_APP_FIRESTORE_STORAGE_BUCKET}`,
  messagingSenderId: `${process.env.REACT_APP_FIRESTORE_MSG_SENDER_ID}`,
  appId: `${process.env.REACT_APP_FIRESTORE_APP_ID}`
};

// start firestore implementation
function listenFirestore(shopId) {
  console.log('START FIRESTORE IMPLEMENTATION');
  // const q2 = query(collection(db, `shops/${shopId}/orders`), where("status", "==", "done"));
  // const unsub2 = onSnapshot(q2, (querySnapshot) => {

  // // querySnapshot.docChanges().forEach(function (change) {
  // //   console.log('Start DocChange')
  // //   console.log(change);
  // //   console.log('End DocChange')
  // // })

  // const orders = [];
  // querySnapshot.forEach((doc) => {
  //   orders.push(doc.data().id);

  // });
  // const lastOrders = orders;
  // console.log("Current orders", orders.join(", "));
  // });

  const q = query(collection(db, `shops/${shopId}/orders`));
  const unsub = onSnapshot(q, (querySnapshot) => {

  console.log('Start DocChange')
  querySnapshot.docChanges().forEach((change) => {
    if (change.type === 'added') {
      let order = change.doc.data();
      // console.log('added', order);
      if (order.status === 'unknown') return;
    }
    else if (change.type === 'modified') {
      let order = change.doc.data();
      let orderStatus = order.status;
      let orderPlatform = order.platform;
      let orderMealType = order.meal_type;
      let orderNumber = order.order_number;
      let orderId = order.id; // 23480
      // console.log('modified', order);
      console.log('doc order status: ', order.status)
      console.log('doc order platform: ', order.platform)
      console.log('doc order mealType: ', order.meal_type)
      console.log('doc order number: ', order.order_number)
      console.log('doc order id: ', order.id)

      // voice call order
      if (orderStatus === 'done') {
        let sound = voiceCall.playVoiceCall(orderId, orderStatus, orderPlatform, orderMealType, orderNumber);
      }
      if (order.status === 'unknown') return;
    }
  })
  console.log('End DocChange')
  });
  // unsub();
  // unsub2();
  console.log('END FIRESTORE IMPLEMENTATION');
};

// Initialize Firebase
const fapp = initializeApp(firebaseConfig, "fapp");
export const fauth = getAuth(fapp); 
export const db = getFirestore(fapp);
// export const fauthPersistence = getAuth(fapp);
export default {
  listenFirestore
}
