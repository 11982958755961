import {useEffect,useState} from 'react';
// import { Tag,Card,PageHeader } from 'antd';
// import { Form, Input, Button, Checkbox } from 'antd';
import {
    Redirect,useHistory 
  } from "react-router-dom";
import api from  '../../services/api';
// import '../counter/standard.css'
import '../common-css/standard.css'
import '../common-css/message.css'
import logo from '../images/svg/logo.svg' 
import { signInAnonymously } from 'firebase/auth';
import { fauth } from '../../firebase';



const layout = {
    labelCol: { span: 8 },
    wrapperCol: { span: 16 },
  };
const tailLayout = {
wrapperCol: { offset: 8, span: 16 },
};


function Login() {
    const [data,setData]=useState([]);
    const [account,setAccount] = useState('')
    const [password,setPassword] = useState('')
    const [loginErrorMsg,setLoginErrorMsg] = useState(false)
    const [loginNoWifi,setLoginNoWifi] = useState(false)
    // const [directUrl,setDirectUrl]=useState(null)

    const history = useHistory();
    

    useEffect(()=>{
        // async function checkLogin(){
        //     let result = await api.checkLoginApi()
        //     console.log('checkLoginApi:',result)
        //     if(result&&result.account_id){
        //         history.push(`/counter/${result.account_id}`);
        //     }
        // }
        // checkLogin()

    },[])

    const inputAccountChange = (e) =>{
        setAccount(e.target.value)
    }

    const inputPasswordChange = (e) =>{
        setPassword(e.target.value)
    }

    const loginButton = async() =>{
        try{
            let result = await api.loginApi(account,password)
            console.log('result:',result)

            // firebase auth anonymous login
            signInAnonymously(fauth)
            .then(() => {
                console.log("Success firebase auth anonymous login");
            })
            .catch((error) => {
                const errorCode = error.code;
                const errorMessage = error.message;
                // ...
              });

            if(result&&result.account_id){
                console.log('success login!!!')
                // setDirectUrl({ redirect: "/counter" });
                history.push(`/counter`);
            }else{
                console.log(result)
                console.log(result.message)
                if(result&&result.message){
                    console.log('kkk')
                    if(result.message.indexOf('Network Error')>-1){
                        console.log('無網路')
                        setLoginNoWifi(true)
                    }
                }
                setLoginErrorMsg(true)
            }
        }catch(e){
            console.log('發生錯誤:',e)
            setLoginErrorMsg(true)

        }
    }

    // if (directUrl) {
        // console.log(directUrl)
        // return <Redirect to={{patchname:directUrl}} />
    // }

    return (
        
      <div>
          <section className="pin_login flex_center flex_wrap">
            {/* <div className="logo"><img src="images/logo_white.png"/></div> */}
            {/* <div>
                <div className="" style={{  width: '80px',height: '80px',
                    borderRadius: '99px',
                    boxShadow: '0px 5px 10px rgba(55, 84, 170, 0.2)',
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    backgroundColor: 'white',
                        
                    }}>
                    <img style={{  
                        width: '40px',
                        color: 'linear-gradient(107.8deg, #FF8008 0%, #FFC837 100%)'
                    }} src={logo}></img>
                </div>
            </div>
             */}
            <div className="flex_center flex_wrap" style={{"color":"white","fontSize":'30px'}} >
                
                <div className="flex_center">
                    <div className="" style={{  width: '80px',height: '80px',
                        borderRadius: '99px',
                        boxShadow: '0px 5px 10px rgba(55, 84, 170, 0.2)',
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                        backgroundColor: 'white',
                            
                        }}>
                        <img style={{  
                            width: '40px',
                            color: 'linear-gradient(107.8deg, #FF8008 0%, #FFC837 100%)'
                        }} src={logo}></img>
                    </div>
                </div>
                <div className="" style={{"margin-top":"10px","color":"white","fontSize":'40px',width:'100%',display:'flex',justifyContent:'center'}} >
                    IDOO 愛點餐飲
                </div>
                <div className="flex_center" style={{width:'100%'}}>
                    櫃檯點餐系統
                </div>
            </div>

            <div className="box flex_center flex_wrap">
                <h2>請輸入帳號密碼</h2>
                <div className="pic_code flex_center">
                    <input onChange={inputAccountChange}/><br/>
                </div>
                <div className="pic_code flex_center">

                    <input type="password" onChange={inputPasswordChange}/>
                </div>

                {/* <h2>請輸入PIN碼</h2>
                <div className="pic_code flex_center">
                    <input type="number" maxLength="1" value=""/>-
                    <input type="number" maxLength="1" value=""/>-
                    <input type="number" maxLength="1" value=""/>-
                    <input type="number" maxLength="1" value=""/>
                </div> */}
                <button onClick={loginButton} type="submit" className="login_btn">登入</button>
            </div>
            </section>
            <div  style={loginErrorMsg?{display:'flex'}:{display:'none'}}  id="modal">
                <div className="modalContainer">
                    <span className="modalClose" onClick={()=>{
                        setLoginErrorMsg(false)
                    }} >&times;</span>
                    <span className="modalTitle">登入失敗</span>

                    {loginNoWifi?
                        <span className="modalContent">連線失敗 /<br/>請確認網路連線</span>
                        :<span className="modalContent">請檢查帳號密碼 /<br/>請至店家後台確認帳號密碼</span>}
                    <button className="modalSubmit"  onClick={()=>{
                        setLoginErrorMsg(false)
                        setLoginNoWifi(false)
                    }} >
                        <span>確認</span>
                    </button>
                </div>
            </div>
        {/* <PageHeader
            className="site-page-header"
            onBack={() => null}
            title="愛點餐飲 - 櫃檯點餐"
            subTitle="智慧餐飲零售平台"
            />
        <div className="App">
        <Form
            {...layout}
            name="basic"
            initialValues={{ remember: true }}
            onFinish={onFinish}
            onFinishFailed={onFinishFailed}
            >
            <Form.Item
                label="Username"
                name="username"
                rules={[{ required: true, message: 'Please input your username!' }]}
            >
                <Input />
            </Form.Item>

            <Form.Item
                label="Password"
                name="password"
                rules={[{ required: true, message: 'Please input your password!' }]}
            >
                <Input.Password />
            </Form.Item>

            <Form.Item {...tailLayout} name="remember" valuePropName="checked">
                <Checkbox>Remember me</Checkbox>
            </Form.Item>

            <Form.Item {...tailLayout}>
                <Button type="primary" htmlType="submit">
                Submit
                </Button>
            </Form.Item>
        </Form>
        
        </div> */}
    </div>
    );
  }
  
  export default Login;